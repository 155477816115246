.root{
    @include container(column, center);
    //min-height: 100vh;
    min-width: 100px;   
}

$max-width: 90%;

//constant componets
.navbar{
    @include container(row, space-between);
    min-width: $max-width;
    min-height: 5rem;

    //padding-left: 2rem;
    //margin-right: 2rem;

    border-bottom: solid lightgray 1px;
    
    .VectorKKCLogo{
        min-width: 6em;
        height: auto;
    }

    .navbar-access{
        @include container(row);
        display: None;

        *{
            width: 120px;
        }
    }

    .VectorMenuIcon{
        min-width: 2.5em;
        width: 5.5em;
        height: auto;
        justify-self: flex-end;

        *{
            width: 100%;
            height: 100%;
        }

        .navbar-dropdown{
            //display: none;
            opacity: 0;
            top: -15rem;
            left: 10%;
            width: 80%;
            height: 5rem;
            position: absolute;
            transition-delay: 0.1s;
            transition-property: transform, opacity;
            transition-duration: 0.5s;
            //overflow: auto;
            z-index: 2;

            
    
            button{
                text-align: left;
                width: 100%;
                font-size: 1rem;
                background-color: white;
                box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

                &:hover{
                    background-color: lightgray;
                }
            }
       
        }

        &:hover{
            cursor: pointer;
            background-color: lightgray;
        }
        
    }

    .navbar-contact-btn{
        display: None
    }
}

.footer{
    @include container(column);
    flex-wrap: wrap;
    width: $max-width;


    .footer-access{
        display: grid;
        grid-template-columns: auto auto;

        width: 70%;
        row-gap: 1rem;
    }

    p{
        width: 100%;
        font-size: 0.875rem;
    }
}


//page stuff
.intro{
    @include container(column);
    width: $max-width;
    align-items: flex-start;
    text-align: start;

    *{
        width: 100%;
    }
}

.intro-video{
    @include container(column);
    width: 100%;
    height: 204px;
    


    video{
        width: 100%;
        height: 120%;
        transform: translateY(1rem);
        object-fit: cover;
    }
}

p{
    width: $max-width;
    font-family: inherit;
    font-size: 1.125rem;
}

h1{
    width: $max-width;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
}

.main-gif{
    width: 100%;
}


.trusted-section{
    @include container(column);
    width: 90%;

    h2{
        font-weight: bold;
    }

    .trusted-logos{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        img{
            width: 18rem;
            filter: grayscale(1);

            &:hover{
                filter: grayscale(0);
            }
        }
    }
}

.our-services{
    @include container(column);
    width: 90%;

    .services{
        //@include container(column);
        display: grid;
        grid-template-columns: auto;
        width: 90%;

        .service{
            @include container(column);
        }

        .vector{
            width: 2em;
            height: 2em;
        }

        a{
            font-weight: bold;


            &:visited, &:hover, &:active {
                color: inherit;
              }
        }
    }

}

.about-us{ 

    div{
        @include container(column)
    }
    
    img{
        width: 90%;
    }
}

/////////About us page
.about-us-container{
    @include container(column);
    width: $max-width;
}

.about-us-intro{
    @include container(column);
    justify-content: flex-start;
    text-align: left;
    width: 100%;
}

.about-us-outro{
    @include container(column);
    width: $max-width;
    text-align: start;
    h2{
        font-weight: bold;
        width: 100%;
    }
}


////////Services page
.service-page-intro{
    @include container(column);
    width: 100%;
    text-align: start;
}

.services-list{
    @include container(column, flex-start, start);
    width: 100%;
    text-align: start;

    .services-sidebar-access{
        //margin-left: 4.5rem;
        display: grid;
        row-gap: 1rem;
        
        a{
            scroll-behavior: smooth;
            text-decoration: none;
            padding: 1rem 0;
            padding-left: 1.5rem;
        }
    }

    .service-info{
        @include container(column);
        width: 100%;

        li{
            width: 90%;
            margin-bottom: 1rem;
            margin-left: 2.5rem;
        }
    }

}

.highlighed-anchor{
    border-left: solid;
}

/////404
.path404{
    width: 99vw;
    height: 99vh;
    border: solid;

    .svg{
        height: 4rem;
    }
}

//about us popup
.active-dropdow{
    transform: translateY(21rem);
}