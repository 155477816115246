@import url('https://fonts.googleapis.com/css?family=Montserrat');

@mixin container($dir:colume, $justify:center, $horz:center){
    display: flex;
    align-items: $horz;
    justify-content: $justify;
    flex-direction: $dir;
}



@import './homePage';


//font sizes

$Color-sec: gray;


//constats
html{
    scroll-behavior: smooth;

    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;

        padding: 1rem;
        margin: 1rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: var(--lightestgrey); 
        border: solid 3px transparent;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888; 
        border-radius: 5px;
        border: solid 2px white;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

body{
    padding: 0px;
    margin: 0px;
    font-family: 'Montserrat';
    text-align: center;   

    @include container(column);
}
p{
    font-size: 1em;
    color: $Color-sec;
}

a{
    color: black;
    text-decoration: none;
}
a:visited, a:hover, a:active {
    color: inherit;
  }



///buttons
.btn-pr{
    width: 90%;
    font-size: 1rem;
    padding: 0.75rem 1.25rem;
    background-color: #101828;
    color: white;
    font-family: inherit;
    font-weight: bold;
    border: 0px;
    transition: background-color;
    transition-duration: 0.5s;
    text-align: center;

    &:hover{
        background-color: rgb(0, 81, 255);
        cursor: pointer;
    }

    &:visited, &:active, &:link{
        color: white;
    }
    }

.btn-sec{
    width: 90%;
    font-size: 0.75rem;
    padding: 0.75rem 1.25rem 0.75rem 1.25rem;
    color: $Color-sec;
    font-family: inherit;
    font-weight: bold;
    border: 0px;
    background-color: transparent;
    transition: background-color;
    transition-duration: 0.5s;

    &:hover{
        color: black;
        cursor: pointer;
    }
    }

    
//white breaks
.break-05{
    margin-bottom: 0.5rem;
}

.break-1{
    margin-bottom: 1rem;
}

.break-2{
    margin-bottom: 2rem;
}

.break-4{
    margin-bottom: 4rem;
}

.break-8{
    margin-bottom: 8rem;
}


@import './mediaQuries';