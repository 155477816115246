//default is small screen > 480px

//meduim screen 480<x<770
@media only screen and (min-width: 480px) {
    .footer{
        .footer-access{
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    .intro-video{
        height: 352px;
    }
  }


//tablet 770<x<1000 
@media only screen and (min-width: 780px) {
    .our-services{
        .services{
            grid-template-columns: auto auto;
        }
    }

    .btn-pr{
        width: 9rem;
    }

    .about-us{
        @include container();
        width: $max-width;
        min-height: auto;
        
        div{
            order: 2;
        }
        
        img{
            width: 50%;
            height: 500px;
            object-fit: cover;
            //fill contain none cover
            
        }
    }

    .intro-video{
        height: 512px;
    }
  }


  
//desktop 1000<x<1400 
@media only screen and (min-width: 1000px) {
    .main-gif{
        width: 90%;
    }

     .intro-video{
         width: $max-width;
     }

    .navbar{
        .navbar-access{
            display: flex;
        }

        .VectorMenuIcon{
            display: none;
        }

        .navbar-contact-btn{
            display: block;
        }
    }

    .footer{
        flex-direction: row;
        justify-content: space-between;

        .break-4{
            margin-bottom: 2rem;
        }
        

    }

    .services-list{
        display: grid;
        grid-template-columns: 30% auto;
        width: 90%;

        .services-sidebar-access{
            position: sticky;
            top: 1rem;
        }

    }
  }



//over sized desktop x<1400 
@media only screen and (min-width: 1400px) {
    // #root{
    //     max-width: 1400px;
    // }
}
