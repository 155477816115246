@import url("https://fonts.googleapis.com/css?family=Montserrat");
.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 100px;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  min-width: 90%;
  min-height: 5rem;
  border-bottom: solid lightgray 1px;
}
.navbar .VectorKKCLogo {
  min-width: 6em;
  height: auto;
}
.navbar .navbar-access {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: None;
}
.navbar .navbar-access * {
  width: 120px;
}
.navbar .VectorMenuIcon {
  min-width: 2.5em;
  width: 5.5em;
  height: auto;
  justify-self: flex-end;
}
.navbar .VectorMenuIcon * {
  width: 100%;
  height: 100%;
}
.navbar .VectorMenuIcon .navbar-dropdown {
  opacity: 0;
  top: -15rem;
  left: 10%;
  width: 80%;
  height: 5rem;
  position: absolute;
  transition-delay: 0.1s;
  transition-property: transform, opacity;
  transition-duration: 0.5s;
  z-index: 2;
}
.navbar .VectorMenuIcon .navbar-dropdown button {
  text-align: left;
  width: 100%;
  font-size: 1rem;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.navbar .VectorMenuIcon .navbar-dropdown button:hover {
  background-color: lightgray;
}
.navbar .VectorMenuIcon:hover {
  cursor: pointer;
  background-color: lightgray;
}
.navbar .navbar-contact-btn {
  display: None;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  width: 90%;
}
.footer .footer-access {
  display: grid;
  grid-template-columns: auto auto;
  width: 70%;
  row-gap: 1rem;
}
.footer p {
  width: 100%;
  font-size: 0.875rem;
}

.intro {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  align-items: flex-start;
  text-align: start;
}
.intro * {
  width: 100%;
}

.intro-video {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 204px;
}
.intro-video video {
  width: 100%;
  height: 120%;
  transform: translateY(1rem);
  -o-object-fit: cover;
     object-fit: cover;
}

p {
  width: 90%;
  font-family: inherit;
  font-size: 1.125rem;
}

h1 {
  width: 90%;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}

.main-gif {
  width: 100%;
}

.trusted-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
}
.trusted-section h2 {
  font-weight: bold;
}
.trusted-section .trusted-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.trusted-section .trusted-logos img {
  width: 18rem;
  filter: grayscale(1);
}
.trusted-section .trusted-logos img:hover {
  filter: grayscale(0);
}

.our-services {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
}
.our-services .services {
  display: grid;
  grid-template-columns: auto;
  width: 90%;
}
.our-services .services .service {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.our-services .services .vector {
  width: 2em;
  height: 2em;
}
.our-services .services a {
  font-weight: bold;
}
.our-services .services a:visited, .our-services .services a:hover, .our-services .services a:active {
  color: inherit;
}

.about-us div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.about-us img {
  width: 90%;
}

.about-us-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
}

.about-us-intro {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
}

.about-us-outro {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  text-align: start;
}
.about-us-outro h2 {
  font-weight: bold;
  width: 100%;
}

.service-page-intro {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: start;
}

.services-list {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  text-align: start;
}
.services-list .services-sidebar-access {
  display: grid;
  row-gap: 1rem;
}
.services-list .services-sidebar-access a {
  scroll-behavior: smooth;
  text-decoration: none;
  padding: 1rem 0;
  padding-left: 1.5rem;
}
.services-list .service-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.services-list .service-info li {
  width: 90%;
  margin-bottom: 1rem;
  margin-left: 2.5rem;
}

.highlighed-anchor {
  border-left: solid;
}

.path404 {
  width: 99vw;
  height: 99vh;
  border: solid;
}
.path404 .svg {
  height: 4rem;
}

.active-dropdow {
  transform: translateY(21rem);
}

html {
  scroll-behavior: smooth;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
html ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  padding: 1rem;
  margin: 1rem;
}
html ::-webkit-scrollbar-track {
  background: var(--lightestgrey);
  border: solid 3px transparent;
}
html ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
  border: solid 2px white;
}
html ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  padding: 0px;
  margin: 0px;
  font-family: "Montserrat";
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

p {
  font-size: 1em;
  color: gray;
}

a {
  color: black;
  text-decoration: none;
}

a:visited, a:hover, a:active {
  color: inherit;
}

.btn-pr {
  width: 90%;
  font-size: 1rem;
  padding: 0.75rem 1.25rem;
  background-color: #101828;
  color: white;
  font-family: inherit;
  font-weight: bold;
  border: 0px;
  transition: background-color;
  transition-duration: 0.5s;
  text-align: center;
}
.btn-pr:hover {
  background-color: rgb(0, 81, 255);
  cursor: pointer;
}
.btn-pr:visited, .btn-pr:active, .btn-pr:link {
  color: white;
}

.btn-sec {
  width: 90%;
  font-size: 0.75rem;
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
  color: gray;
  font-family: inherit;
  font-weight: bold;
  border: 0px;
  background-color: transparent;
  transition: background-color;
  transition-duration: 0.5s;
}
.btn-sec:hover {
  color: black;
  cursor: pointer;
}

.break-05 {
  margin-bottom: 0.5rem;
}

.break-1 {
  margin-bottom: 1rem;
}

.break-2 {
  margin-bottom: 2rem;
}

.break-4 {
  margin-bottom: 4rem;
}

.break-8 {
  margin-bottom: 8rem;
}

@media only screen and (min-width: 480px) {
  .footer .footer-access {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .intro-video {
    height: 352px;
  }
}
@media only screen and (min-width: 780px) {
  .our-services .services {
    grid-template-columns: auto auto;
  }
  .btn-pr {
    width: 9rem;
  }
  .about-us {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: colume;
    width: 90%;
    min-height: auto;
  }
  .about-us div {
    order: 2;
  }
  .about-us img {
    width: 50%;
    height: 500px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .intro-video {
    height: 512px;
  }
}
@media only screen and (min-width: 1000px) {
  .main-gif {
    width: 90%;
  }
  .intro-video {
    width: 90%;
  }
  .navbar .navbar-access {
    display: flex;
  }
  .navbar .VectorMenuIcon {
    display: none;
  }
  .navbar .navbar-contact-btn {
    display: block;
  }
  .footer {
    flex-direction: row;
    justify-content: space-between;
  }
  .footer .break-4 {
    margin-bottom: 2rem;
  }
  .services-list {
    display: grid;
    grid-template-columns: 30% auto;
    width: 90%;
  }
  .services-list .services-sidebar-access {
    position: sticky;
    top: 1rem;
  }
}/*# sourceMappingURL=index.css.map */